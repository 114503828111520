<template>
  <div class="accordion-item" :class="{ 'is-active': active }">
    <dt class="accordion-item-title">
      <button @click="toggle" class="accordion-item-trigger">
        <h4 class="accordion-item-title-text">{{ item?.question }}</h4>
        <div class="accordion-item-trigger-icon">
          <svg
            width="3.75rem"
            height="3.75rem"
            viewBox="0 0 60 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <TransitionGroup name="fade">
              <path
                :key="!active"
                v-if="!active"
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M30 20C28.8954 20 28 20.8954 28 22V28L22 28C20.8954 28 20 28.8954 20 30C20 31.1046 20.8954 32 22 32H28V38C28 39.1046 28.8954 40 30 40C31.1046 40 32 39.1046 32 38V32H38C39.1046 32 40 31.1046 40 30C40 28.8954 39.1046 28 38 28L32 28V22C32 20.8954 31.1046 20 30 20Z"
                fill="#377DFF"
              />
              <rect
                :key="active"
                x="40"
                y="28"
                v-else
                width="4"
                height="20"
                rx="2"
                transform="rotate(90 40 28)"
                fill="#BEC1CE"
              />
            </TransitionGroup>

            <!-- <rect
              x="1.5"
              y="1.5"
              width="57"
              height="57"
              rx="28.5"
              stroke="#BEC1CE"
              stroke-width="3"
            /> -->
            <rect
              x="1.5"
              y="1.5"
              width="57"
              height="57"
              rx="28.5"
              stroke="#377DFF"
              stroke-width="3"
            />
          </svg>
        </div>
      </button>
    </dt>
    <Transition
      name="accordion-item"
      @enter="startTransition"
      @after-enter="endTransition"
      @before-leave="startTransition"
      @after-leave="endTransition"
    >
      <dd v-if="active" class="accordion-item-details">
        <div v-html="item.answer" class="accordion-item-details-inner"></div>
      </dd>
    </Transition>
  </div>
</template>

<script>
import { ref, computed } from "vue";
import { useStore } from "vuex";
export default {
  props: ["item"],
  setup(props) {
    const store = useStore();
    const active = ref(false);
    // const question =  computed(() => store.getters["faq/getAnswer"]),
    const toggle = () => {
      active.value = !active.value;
      if (!props.item.answer) {
        store.dispatch("faq/getFaqId", props.item.id);
      }
    };
    const startTransition = (el) => {
      el.style.height = el.scrollHeight + "px";
    };
    const endTransition = (el) => {
      el.style.height = "";
    };
    return {
      parent,
      startTransition,
      endTransition,
      toggle,
      active,
      question: computed(() => store.getters["faq/getAnswer"]),
    };
  },
  components: {  },
};
</script>

<style lang="scss" scoped>
@use "sass:math";

$browser-context: 16;
$blue: #1779ba;
$gray: #cacaca;
$red: #cc4b37;
$white: #ffffff;

// @function em($pixels, $context: $browser-context) {
//   @return #{$pixels/$context}em;
// }

.btn-action-delete {
  color: $red;
  cursor: pointer;
}

.accordion {
  padding: 0;

  &-item {
    font-weight: 500;
    line-height: 1.5;

    &:first-child {
      border-top: 0.06rem solid var(--light-gray);
    }
    border-bottom: 0.06rem solid var(--light-gray);
  }

  div:last-child .accordion-item-details {
    border-radius: 5px;
  }

  dd {
    margin-left: 0;
  }
}

.accordion-item-trigger {
  padding: 2.25rem 0rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.accordion-item-details-inner {
  font-weight: 400;
  padding-bottom: 2.25rem;
}
.accordion-item-title {
  position: relative;

  h4 {
    font-size: 1.31em;
    margin-bottom: 0;
    padding-right: 1.38rem;
    line-height: 144%;
    font-weight: 700;
    color: var(--dark-blue);
  }
}

.accordion-item-trigger {
  width: 100%;
  text-align: left;
  background-color: transparent;
  border: none;
}

.accordion-item-trigger-icon {
  display: block;
  width: 3.75rem;
  height: 3.75rem;
  transition: all 0.3s ease;

  svg {
    rect {
      transition: all 0.3s ease;
    }
  }

  .is-active & {
    transform: rotate(180deg);

    svg {
      rect {
        stroke: #bec1ce;
      }
    }
  }
}

.accordion-item-details {
  overflow: hidden;
  // background-color: whitesmoke;
  font-size: 1.25rem;
  font-weight: 400;
}

.accordion-item-enter-active,
.accordion-item-leave-active {
  will-change: height;
  transition: height 0.2s ease;
}
.accordion-item-enter-from,
.accordion-item-leave-to {
  height: 0 !important;
}

@media screen and (max-width: 768px) {
  .accordion-item-title {
    h4 {
      font-size: 0.88rem;
    }
  }

  .accordion-item-trigger-icon {
    width: 2.19rem;
    height: 2.19rem;

    svg {
      width: 2.19rem;
      height: 2.19rem;
    }
  }
}
</style>
