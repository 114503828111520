<template>
  <div class="questions">
    <div class="container">
      <h2 class="title primary-title">{{ $t("questionPage.title") }}</h2>
      <div class="content">
        <div v-for="(item, index) in data" :key="index">
          <AcordionItem :item="item" :groupId="new Date().now" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "@vue/reactivity";
import { useStore } from "vuex";
import AcordionItem from "../components/AcordionItem.vue";

const store = useStore();

store.dispatch("faq/getFaq");

const data = computed(() => store.getters["faq/getResults"]);

</script>

<style lang="scss" scoped>
.questions {
  .title {
    font-size: 1.9em;
    font-weight: 600;
    margin-top: 3.75rem;
    margin-bottom: 2.69rem;
  }
  .content {
    grid-column: 1/18;
  }
}

@media screen and (max-width: 992px) {
  .conatainer-grid {
    --max-width: 90%;
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .questions {
    display: block;
    .title {
      margin-top: 1.88rem;
      margin-bottom: 2.5rem;
      font-size: 1.3rem;
    }
  }
}
</style>
